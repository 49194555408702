:root {
    --black: #202731;

    /*purple to orange*/
    --dark-pink: #C62267;
    --light-pink: #DA3E67;
    --dark-orange: #EB5967;
    --light-orange: #FA7269;

    --light-orink: #E9758B;

    /*light blue to dark blue*/
    --lightest-blue: #4facf7;
    --light-blue: #1c97fd;
    --dark-blue: #0080fe;
    --darkest-blue: #0066ff;

    /*dark blue to white*/
    /*--darkest-blue: #0066ff;*/
}


.test-2>.makeStyles-centered-58>div {
    animation: fadeInUp 1000ms both
}

.background-container {
    height: 170vh;
    width: 100vw;
    background: var(--darkest-blue);
}

.background-card {
    display: flex;
    justify-content: center;
    align-items: center;
}
.background-card {
    /*height: 100%;*/
    /*padding-bottom: 3vh;*/
    width: 65%;
    border-radius: 3vw;
    background: var(--dark-blue);
    box-shadow: 1.55vw 1.55vw 3.1vw #0057d9, -1.55vw -1.55vw 3.1vw #0075ff;
    /*:after {*/
    /*    content: '';*/
    /*    display: block;*/
    /*    padding-top: calc(100% / (16/10));*/
    /* }*/
}

.entry-animation {
    /*animation: fadeInUp 1500ms ease-in both;*/
    animation: bounceInUp 1000ms ease-out both
}

/*@keyframes fadeInUp {*/
/*    from {*/
/*        opacity: 0;*/
/*        transform: translate3d(0, 100%, 0);*/
/*    }*/

/*    to {*/
/*        opacity: 1;*/
/*        transform: translate3d(0, 0, 0);*/
/*    }*/
/*}*/


@keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}
